import { Product } from "@/services/types";

const featuredProducts = [
  {
    id: 13776931,
    name: "1 Kg Naranjas",
    price: 990.0,
    compare_at_price: 1690.0,
    images: [
      {
        id: 23710615,
        url: "https://images.jumpseller.com/store/maifud/13776931/6BE33C36-1353-438A-8998-1618CF5DD66A.jpg?1658247632",
        position: 1,
      },
    ],
  },
  {
    id: 25360375,
    name: "1 Kg Palta Hass",
    price: 5190,
    compare_at_price: 6490,
    images: [
      {
        id: 25360375,
        url: "https://cdnx.jumpseller.com/maifud/image/25360375/IMG_6001.jpg?1658254990",
        position: 1,
      },
    ],
  },
  {
    id: 12700466,
    name: "Bandeja 30 Huevos Extra",
    price: 10500.0,
    compare_at_price: 12585.0,
    images: [
      {
        id: 21598355,
        url: "https://images.jumpseller.com/store/maifud/12700466/IMG_2544.jpg?1724714407",
        position: 1,
      },
      {
        id: 21598356,
        url: "https://images.jumpseller.com/store/maifud/12700466/_DSC1226.jpg?1724714407",
        position: 2,
      },
      {
        id: 21598357,
        url: "https://images.jumpseller.com/store/maifud/12700466/IMG_2545.jpg?1724714407",
        position: 3,
      },
    ],
  },
  {
    id: 10999718,
    name: "1 Kg Papas",
    price: 1290.0,
    compare_at_price: 1790.0,
    images: [
      {
        id: 18727134,
        url: "https://images.jumpseller.com/store/maifud/10999718/IMG_2389.jpg?1658247798",
        position: 1,
      },
    ],
  },
  {
    id: 10408861,
    name: "1 Kg Zanahorias",
    price: 700.0,
    compare_at_price: 1190.0,
    images: [
      {
        id: 18024881,
        url: "https://images.jumpseller.com/store/maifud/10408861/0D6FF30C-5743-4D47-87ED-7D6EFFCD2ABB.jpeg?1722868569",
        position: 1,
      },
      {
        id: 18024882,
        url: "https://images.jumpseller.com/store/maifud/10408861/BAACF9FB-42E7-482B-B9BE-3AD367FDE849.jpeg?1722868569",
        position: 3,
      },
      {
        id: 51285055,
        url: "https://images.jumpseller.com/store/maifud/10408861/_DSC0918.jpg?1722868569",
        position: 4,
      },
      {
        id: 51285056,
        url: "https://images.jumpseller.com/store/maifud/10408861/_DSC0956.jpg?1722868569",
        position: 5,
      },
    ],
  },
  {
    id: 45673451,
    name: "Bifum Fideo Arroz 200 grs",
    price: 2000,
    compare_at_price: 2500,
    images: [
      {
        id: 45673451,
        url: "https://cdnx.jumpseller.com/maifud/image/45673451/thumb/270/360?1708390270",
        position: 1,
      },
    ],
  },
  {
    id: 12197035,
    name: "1 Kg Tomates Imperfectos",
    price: 1500.0,
    compare_at_price: 1990.0,
    images: [
      {
        id: 20537159,
        url: "https://images.jumpseller.com/store/maifud/12197035/2B7AA06F-FF34-4072-82E9-3A8F3F280EB4.jpeg?1640568561",
        position: 1,
      },
    ],
  },
  {
    id: 30947021,
    name: "Wild Protein Bar Caramelo 45G 5U",
    price: 4400,
    compare_at_price: 6290,
    images: [
      {
        id: 30947021,
        url: "https://cdnx.jumpseller.com/maifud/image/30947021/thumb/270/360?1673838262",
        position: 1,
      },
    ],
  },
] as Product[];

export default featuredProducts;
